import * as React from "react";
import {Link} from "gatsby";
import Layout from "../../components/layout/Layout";
import SplashBanner from "../../components/SplashBanner";
import ServicesGrid from "../../components/servicesGrid";
import Brochures from "../../components/Brochures";

const bannerImg = '../../banners/services_banner.jpg';

//RENDER
export default function ServicesPage() {
    return (
        <Layout pageTitle={'Our Services'}>
            <SplashBanner
                title={"Services"}
                content={"Offering bespoke personnel solutions & equipment brokerage to customers globally across the Energy sector."}
                backgroundImg={bannerImg}
            />

            {/*Global support*/}
            <section id="ourServicesIntro">
                <div className="container">
                    <div className="split-content">
                        <div className="module text">
                            <h2>Global support</h2>
                            <p>Our experience mobilizing personnel to some of the harshest conditions on the planet, has
                                provided us the understanding that environments can make a significant difference to the
                                complexity of a client's project.</p>
                            <p>With over a decade of mobilising personnel to over 45 countries spanning the entirety of
                                Africa, Asia, Europe, North America, South America & the Middle East.</p>
                            <p>Our solutions are unique to each client and combine international service quality with
                                local compliance in each continent, while providing access to a network of trusted local
                                and expatriate workforces for each country.</p>
                            <Link to={'/contact-us'} className="button primary arrow-light">
                                <span>Get in touch</span>
                            </Link>
                        </div>
                        <div className="module media">
                            <img
                                src={'../../article/services_article.jpg'}
                                alt="Global support"/>
                        </div>
                    </div>
                </div>
            </section>

            <section id="servicesGrid">
                <ServicesGrid/>
            </section>


            {/*Brochures*/}
            <Brochures/>

        </Layout>
    )
}
