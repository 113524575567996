import React from 'react';
import { Helmet } from "react-helmet";
// import {BrowserRouter as Router} from 'react-router-dom';
import '../../scss/global.scss';
import Navbar from "../header/Navbar";
import Footer from "../Footer";

export default function Layout({pageTitle, children}) {
    return (
        <div className="layout">

            <Helmet>
                <meta charSet="utf-8" />
                <title>{pageTitle ? `${pageTitle} | Performance Energy | Specialists in mobilizing experience personnel` : 'Performance Energy | Specialists in mobilizing experience personnel'}</title>
                <link rel="canonical" href="https://www.performance-energy.com"/>
            </Helmet>

            {/*<Router>*/}
            <Navbar />
            {/*</Router>*/}
            <main>
                {children}
            </main>

            <Footer/>
        </div>
    )
}